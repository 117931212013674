import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const IndexHero = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "rsvision_bg.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`rgb(15, 15, 15)`}
          style={{
            width: '100%',
            flexDirection: 'column',
            padding: '1rem 0rem 0rem 0rem',
            backgroundPosition: 'top center',
            backgroundRepeat: 'none',
            backgroundSize: 'cover',
          }}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

export default IndexHero;
