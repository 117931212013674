import React from 'react';
import IndexHero from './index_hero';
import MindMap from '../images/mindmap.svg';
import TypingCode from '../images/typing_code.svg';
import ReleaseIllustration from '../images/release_illustration.svg';

const IntroHero = () => (
  <IndexHero>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h1 className="text-center py-5 index_hero">
            Web and Software Solutions
          </h1>
        </div>
      </div>
    </div>
    <div className="hero_bottom_wrap mt-3">
      <div className="container-fluid">
        <div className="content_wrap_small">
          <div className="row">
            <div className="col-4 col-sm-4">
              <div className="h-100 d-flex flex-column">
                <img
                  src={MindMap}
                  width="200"
                  className="img-fluid"
                  alt="Mind-Mapping"
                />
                <div className="mt-auto">
                  <h4>Comprehensive Planning</h4>
                </div>
              </div>
            </div>
            <div className="col-4 col-sm-4">
              <div className="h-100 d-flex flex-column pt-3">
                <img
                  src={TypingCode}
                  width="300"
                  className="img-fluid"
                  alt="Code"
                />
                <div className="mt-auto">
                  <h4>Reusable Code</h4>
                </div>
              </div>
            </div>
            <div className="col-4 col-sm-4">
              <div className="h-100 d-flex flex-column">
                <img
                  src={ReleaseIllustration}
                  width="150"
                  className="img-fluid"
                  alt="Above and Beyond"
                />
                <div className="mt-auto">
                  <h4>Above &amp; Beyond</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </IndexHero>
);

export default IntroHero;
