import React from 'react';
import NetlifyLogo from '../images/Netlify_logo.svg';
import ReactLogo from '../images/React-icon.svg';
import WordpressLogo from '../images/WordPress_logo.svg';
import GatsbyLogo from '../images/Gatsby-Logo.svg';
import GraphQLLogo from '../images/GraphQL_Logo.svg';
import RubyRailsLogo from '../images/Ruby_On_Rails_Logo.svg';
import Fade from 'react-reveal/Fade';

const Stack = () => (
  <Fade bottom cascade>
    <div className="row text-center">
      <div className="col-4 col-sm-4 col-md-2 ">
        <img
          src={ReactLogo}
          width="130"
          className="img-fluid"
          alt="React JS and Native"
        />
      </div>
      <div className="col-4 col-sm-4 col-md-2">
        <img
          src={GatsbyLogo}
          width="160"
          className="img-fluid mt-3"
          alt="Gatsby"
        />
      </div>
      <div className="col-4 col-sm-4 col-md-2 ">
        <img
          src={GraphQLLogo}
          width="80"
          className="img-fluid mt-1"
          alt="graphQL"
        />
      </div>
      <div className="col-4 col-sm-4 col-md-2">
        <img
          src={RubyRailsLogo}
          width="160"
          className="img-fluid mt-1"
          alt="Ruby on Rails"
        />
      </div>
      <div className="col-4 col-sm-4 col-md-2">
        <img
          src={WordpressLogo}
          width="160"
          className="img-fluid mt-4"
          alt="Wordpress"
        />
      </div>
      <div className="col-4 col-sm-4 col-md-2">
        <img
          src={NetlifyLogo}
          width="160"
          className="img-fluid mt-3"
          alt="Netlify"
        />
      </div>
    </div>
  </Fade>
);

export default Stack;
