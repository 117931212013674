import React from 'react';
import Contact from '../components/contact';
import Layout from '../components/layout';
import Stack from '../components/stack';
import IntroHero from '../components/intro_hero';
import IndexOtherServices from '../components/other_services';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Redesign from '../images/redesign.svg';
import AnyDevice from '../images/any_device.svg';
import SEO from '../components/utils/seo';

const Home = () => (
  <>
    <SEO title="RSVision Web and Software Solutions" />
    <Layout>
      <IntroHero />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h2 className="text-center py-3">The Tech Stack</h2>
          </div>
        </div>
        <div className="content_wrap">
          <Stack />
          <div className="row">
            <div className="col-md-12">
              <Fade bottom>
                <h4 className="float-right text-muted py-3">
                  <small>and many more...</small>
                </h4>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <IndexOtherServices />
      <div className="container-fluid">
        <div className="content_wrap">
          <div className="row">
            <div className="col-lg-6">
              <Zoom right>
                <h4 className="text-center my-3">
                  Redesign? Refactors and Upgrades? No Problem.
                </h4>
              </Zoom>
              <img
                src={Redesign}
                alt="Redesign or Refactor"
                className="img-fluid float-right"
                width="300"
              />
              <p>
                If your existing project is gathering dust, needs a check-up or
                a whole new upgrade; feel free to get in-touch for a free
                project consultation!
              </p>
              <p>
                No matter the scale of your project, no matter the tediuous
                details, RSVision Web and Software Solutions has you covered.
              </p>
              <Zoom left>
                <h4 className="text-center my-3">
                  Brought to Life on Every Device
                </h4>
              </Zoom>
              <img
                src={AnyDevice}
                alt="Redesign or Refactor"
                className="img-fluid float-left"
                width="300"
              />
              <p>
                Native devices or web presence experience, all of it is
                protected with the latest in security standards.
              </p>
            </div>
            <div className="col-lg-6">
              <Contact />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </>
);

export default Home;
