import React from 'react';
import SecurityImg from '../images/security.svg';
import DesignComponents from '../images/design_components.svg';
import DataImg from '../images/data.svg';
import Parallax from './parallax';
import Fade from 'react-reveal/Fade';

const IndexOtherServices = () => (
  <Parallax imagename="broken.png">
    <Fade bottom>
      <div className="container-fluid py-4">
        <div className="content_wrap">
          <div className="row">
            <div className="col-sm-4 mb-3">
              <div className="card dark h-100 d-flex flex-column">
                <img
                  src={DataImg}
                  alt=""
                  width="400"
                  className="img-fluid mb-3"
                />
                <div className="mt-auto">
                  <Fade left>
                    <h4 className="text-center">Presence Growth</h4>
                  </Fade>
                  <span>
                    Grow your presence and fine tune your analytics to best suit
                    your needs.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <div className="card dark h-100 d-flex flex-column pt-3">
                <img
                  src={DesignComponents}
                  alt=""
                  width="400"
                  className="img-fluid mt-2 mb-3"
                />
                <div className="mt-auto">
                  <Fade bottom>
                    <h4 className="text-center">Extensive Design</h4>
                  </Fade>
                  <span>
                    With over a decade of experience, anything is possible for
                    your next project.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <div className="card dark h-100 d-flex flex-column">
                <img
                  src={SecurityImg}
                  alt=""
                  width="400"
                  className="img-fluid  mb-3"
                />
                <div className="mt-auto">
                  <Fade right>
                    <h4 className="text-center">Secruity End to End</h4>
                  </Fade>
                  <span>Keeping you and your customers secure and safe.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  </Parallax>
);

export default IndexOtherServices;
