import React, { useState, createRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { navigate } from 'gatsby-link';
import Recaptcha from 'react-google-recaptcha';
import RubberBand from 'react-reveal/RubberBand';

const RECAPTCHA_KEY = process.env.GATSBY_SITE_KEY;

if (typeof RECAPTCHA_KEY === 'undefined') {
  throw new Error(`
    Env var is undefined! 
  `);
}

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default function Contact() {
  const [state, setState] = useState({});
  const recaptchaRef = createRef();

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const recaptchaValue = recaptchaRef.current.getValue();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  return (
    <div className="contact_form">
      <RubberBand>
        <h4 className="text-center">Contact RSVision</h4>
      </RubberBand>
      <Form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/success/"
        onSubmit={handleSubmit}
      >
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            required
            type="text"
            name="name"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            required
            type="email"
            name="email"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Company Name</Form.Label>
          <Form.Control
            required
            type="text"
            name="company-name"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="service">
          <Form.Label>Desired Service</Form.Label>
          <Form.Control name="service" as="select" onChange={handleChange}>
            <option value="Mobile Application Deveoplment">
              Mobile Application Deveoplment
            </option>
            <option value="Content Management System">
              Content Management System
            </option>
            <option value="Front-end Engineering">Front-end Engineering</option>
            <option value="Back-end Engineering">Back-end Engineering</option>
            <option value="SEO Services">SEO Services</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone</Form.Label>
          <Form.Control type="phone" name="phone" onChange={handleChange} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Project Details</Form.Label>
          <Form.Control
            required
            as="textarea"
            rows="3"
            name="message"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
        </Form.Group>
        <Form.Group>
          <div className="float-right">
            <Button type="submit">Send</Button>
          </div>
        </Form.Group>
      </Form>
    </div>
  );
}
